<template>
  <div class="adManagement-container">
    <el-row class="datePicker">
      <ad-date-range-pick
        v-model="dateRange"
        size="small"
        :showTag="true"
      ></ad-date-range-pick>
    </el-row>
    <el-row>
      <!-- 头部tab-->
      <el-tabs
        type="border-card"
        v-model="activeTab"
        @tab-click="handleClickTab"
      >
        <el-tab-pane
          name="campaign"
          :lazy="true"
        >
          <span slot="label"
            >广告系列
            <el-button
              type="primary"
              round
              size="mini"
              v-if="selectCampaigns.filter((v) => v.level == 'campaign').length"
              >已选{{ selectCampaigns.filter((v) => v.level == 'campaign').length }}项</el-button
            >
            <el-button
              type="info"
              round
              size="mini"
              v-else
              >{{ numObj.campaigns }}</el-button
            >
            <el-button
              class="el-icon-refresh"
              type="text"
              @click="refreshTable('campaignTable')"
              v-if="activeTab == 'campaign'"
            ></el-button>
          </span>
          <ad-table
            :date-range="dateRange"
            level="campaign"
            :account-id="accountId"
            :init-data="initData"
            :fields="fields"
            @selection-change="handleSelectionChange"
            :activeLevel="activeTab"
            :adsCountObj="adsCountObj"
            ref="campaignTable"
            @select-tab="selectTab"
            @getNum="getNum"
          ></ad-table>
        </el-tab-pane>
        <el-tab-pane
          name="adset"
          :lazy="true"
        >
          <span slot="label"
            >广告组
            <el-button
              type="primary"
              round
              size="mini"
              v-if="selectAdsets.filter((v) => v.level == 'adset').length"
              >已选{{ selectAdsets.filter((v) => v.level == 'adset').length }}项</el-button
            >
            <el-button
              type="info"
              round
              size="mini"
              v-else
              >{{ numObj.adsets }}</el-button
            >
            <el-button
              class="el-icon-refresh"
              type="text"
              @click="refreshTable('adsetTable')"
              v-if="activeTab == 'adset'"
            ></el-button>
          </span>
          <ad-table
            :date-range="dateRange"
            level="adset"
            :account-id="accountId"
            :init-data="initData"
            :fields="fields"
            @selection-change="handleSelectionChange"
            :activeLevel="activeTab"
            :adsCountObj="adsCountObj"
            :select-ids="selectIds"
            ref="adsetTable"
            @click="refreshTable('adsetTable')"
            @select-tab="selectTab"
            @getNum="getNum"
          ></ad-table>
        </el-tab-pane>
        <el-tab-pane
          name="ad"
          :lazy="true"
        >
          <span slot="label"
            >广告
            <el-button
              type="primary"
              round
              size="mini"
              v-if="selectAds.filter((v) => v.level == 'ad').length"
              >已选{{ selectAds.filter((v) => v.level == 'ad').length }}项</el-button
            >
            <el-button
              type="info"
              round
              size="mini"
              v-else
              >{{ numObj.ads }}</el-button
            >
            <el-button
              class="el-icon-refresh"
              type="text"
              @click="refreshTable('adTable')"
              v-if="activeTab == 'ad'"
            ></el-button>
          </span>
          <ad-table
            :date-range="dateRange"
            level="ad"
            :account-id="accountId"
            :init-data="initData"
            :fields="fields"
            @selection-change="handleSelectionChange"
            :activeLevel="activeTab"
            :adsCountObj="adsCountObj"
            :select-ids="selectIds"
            ref="adTable"
            @click="refreshTable('adTable')"
            @getNum="getNum"
          ></ad-table>
        </el-tab-pane>
        <el-tab-pane name="drafBox">
          <span slot="label"
            >草稿箱
            <el-button
              type="info"
              round
              size="mini"
              >{{ adsCountObj.drafts }}</el-button
            >
          </span>
          <div class="tableWrap">
            <draft-box @get-draft-num="getDraftNum"></draft-box>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <!-- 保存为我的筛选条件 -->
    <el-dialog
      title="保存查询条件"
      :visible.sync="saveFilterShow"
      width="500px"
    >
      <div>
        <el-form label-width="120px">
          <el-form-item label="查询条件名称">
            <el-input
              placeholder="请输入"
              v-model="filterName"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: right">
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button
              type="primary"
              @click="saveMyFilter"
              >保存</el-button
            >
            <el-button
              @click="
                saveFilterShow = false;
                filterName = '';
              "
              >取消</el-button
            >
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import DraftBox from './components/DraftBox.vue';
import {
  report,
  breakdownRules,
  reportConsts,
  breakdown,
  myColumns,
  adsCount,
  myFilters,
  saveFilter,
  deleteFilter,
} from '@/api/adManagement.js';
import AdDateRangePick from '@/views/adManagement/components/AdDateRangePick';
import AdCellInlineEditBudget from '@/views/adManagement/components/table/AdCellInlineEditBudget';
import AdCellName from '@/views/adManagement/components/table/AdCellName';
import AdTable from '@/views/adManagement/components/table/AdTable';
const _ = require('lodash');
export default {
  data() {
    return {
      adsetTableCount: 0,
      adTableCount: 0,
      selection: {},
      selectedRowScopes: [],
      dateRange: {
        actionAttributionWindows: [],
        dateRange: [],
      },
      filterIcon: require('@/assets/icon/filter.png'),
      adType: '创建标准广告',
      keyword: '',
      tableData: [],
      disAbled: false,
      loading: false,
      accountId: this.$store.getters.accountId,
      level: 'campaign',
      activeTab: this.$store.getters.adActiveTab,
      fields: [],
      params: {
        account_id: this.accountId,
        action_attribution_windows: [],
        after: '',
        date_end: '2020-10-01',
        date_start: '2020-02-20',
        fields: [],
        filtering: [],
        object_filtering: [],
        keyword: '',
        level: 'campaign',
        page_length: 50,
        sort: [],
      },
      after: '',
      expandAll: false, //是否展开所有下级，当加载细分的时候默认展开
      expandKeys: [],
      scrollDisable: false, //表格无限滚动是否禁用
      loadText: '滚动加载更多', //默认加载文字
      breakdownRulesArr: [],
      initData: {},
      breakdowms: {
        time: '',
        delivery: '',
      },
      breakdownGroup: [],
      maps: new Map(),
      infiniteScrollDisabled: false,
      lazy: true,
      // 广告数量
      adsCountObj: {},
      selectCampaigns: [],
      selectAdsets: [],
      selectAds: [],
      selectIds: {
        selectCampaigns: [],
        selectAdsets: [],
        selectAds: [],
      },
      myCols: {
        myColumnsArr: [],
        myColumsName: '',
        myColumsId: '',
      },
      // 筛选条件
      filterList: [], //我的自定义筛选条件
      cusFilterList: [{ field: null, operator: '', value: null, value1: [] }],
      filterId: 0,
      filterShow: false,
      checkList: [],
      filterName: '',
      saveFilterShow: false,
      numObj: {},
    };
  },
  components: {
    AdTable,
    AdCellName,
    AdCellInlineEditBudget,
    AdDateRangePick,
    DraftBox,
  },
  filters: {
    showName(item) {
      return item ? (item['name'] ? item['name'] : '') : '请选择';
    },
  },
  computed: {
    // checkList(){
    //     let list = this.params.filtering.filter(v=>v.filed == 'status');
    //     if(list.length){
    //         return list[0].value1;
    //     }else{
    //         this.params.filtering.push({filed:'status',operator:'IN',value1:[],value:''})
    //         return this.params.filtering.filter(v=>v.filed == 'status')[0].value1;
    //     }
    // },
    campaignNum() {
      return this.$refs.campaignTable && this.$refs.campaignTable.summary
        ? this.$refs.campaignTable.summary.total_count
        : this.adsCountObj.campaigns;
    },
    adsetNum() {
      console.log(this.$refs.adsetTable);
      return this.$refs.adsetTable && this.$refs.adsetTable.summary
        ? this.$refs.adsetTable.summary.total_count
        : this.adsCountObj.adsets;
    },
    adNum() {
      return this.$refs.adTable && this.$refs.adTable.summary
        ? this.$refs.adTable.summary.total_count
        : this.adsCountObj.ads;
    },
  },
  watch: {
    // 监听筛选条件的变化
    'params.filtering': {
      handler(v) {
        // console.log(v);
      },
      deep: true,
    },
    level: {
      handler() {
        // if (this.isArrayChange(this.selectIds.selectCampaigns, this.selectCampaigns)) {
        this.selectIds.selectCampaigns = this.selectCampaigns.filter((v) => v.level == 'campaign');
        // }
        // if (this.isArrayChange(this.selectIds.selectAdsets, this.selectAdsets)) {
        this.selectIds.selectAdsets = this.selectAdsets.filter((v) => v.level == 'adset');
        // }
        // if (this.isArrayChange(this.selectIds.selectAds, this.selectAds)) {
        this.selectIds.selectAds = this.selectAds.filter((v) => v.level == 'ad');
        // }
      },
    },
    'this.$store.getters.adActiveTab'() {
      this.activeTab = this.$store.getters.adActiveTab;
      // console.log(this.activeTab);
      if (this.activeTab !== 'drafBox') {
        this.level = this.activeTab;
      }
    },
    dateRange() {
      this.params.action_attribution_windows = this.dateRange.actionAttributionWindows || [];
      this.params.date_start = this.dateRange.dateRange[0];
      this.params.date_end = this.dateRange.dateRange[1];
      localStorage.setItem('adDateRange', JSON.stringify(this.dateRange));
      // this.$store.commit('setDateRange',this.dateRange)
      // this.$store.commit("adsDate/setDateRange",this.dateRange);
    },
  },
  async mounted() {
    if (localStorage.getItem('adDateRange')) {
      this.dateRange = { ...JSON.parse(localStorage.getItem('adDateRange')) };
    }
    // this.dateRange.dateRange = [fnCSTToYmd(new Date(),{tag:1}),fnCSTToYmd(new Date(),{tag:1})]
    this.$store.dispatch('app/openSideBar');
    this.adsCount();
    await this.getInitData();
    // await this.myColumns();
    // this.getMyFilter();
    // this.breakdownRules();
    // ctrl+d 快捷打开复制广告系列-广告组-广告
    this.listenerCtrlD();
  },
  // 实例销毁之前对点击事件进行解绑
  beforeDestroy() {
    // window.removeEventListener('click', this.clickOther);
    window.removeEventListener('keydown', this.ctrlDMethod, true);
  },
  // 注册自定指令
  directives: {
    'infinite-scroll': {
      bind(el, binding) {
        const selectWrap = el.querySelector('.el-table__body-wrapper');
        selectWrap.addEventListener('scroll', function () {
          let sign = binding.value.distance;
          const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value.load();
          }
        });
      },
    },
  },
  methods: {
    getListCount(tableName, val) {
      this[`${tableName}Count`] = val;
    },
    ctrlDMethod() {
      if (event.key == 'd' && event.ctrlKey) {
        event.preventDefault();
        // if (this.$refs[`${this.activeTab}Table`] && this.selectedRowScopes.length) this.$refs[`${this.activeTab}Table`].showDrawer('copy', this.selectedRowScopes, this.activeTab)
        if (!this.selectedRowScopes.length) return false;
        if (this.$refs[`${this.activeTab}Table`] && this.selectedRowScopes.length)
          this.$refs[`${this.activeTab}Table`].editCopyShow = true;
        // console.log("this.selectedRowScopes",this.selectedRowScopes)
      }
    },
    listenerCtrlD() {
      if (this.selectedRowScopes && this.selectedRowScopes.length) {
        window.addEventListener('keydown', this.ctrlDMethod, true);
      }
    },
    // ...mapMutations(["adsDate/setDateRange"]),
    isArrayChange(a, b) {
      if (a.length !== b.length) return true;
      let c = b.concat(a);
      if (!c.some((item) => a.some((aitem) => aitem.id === item.id))) return false;
      if (!c.some((item) => b.some((aitem) => aitem.id === item.id))) return false;
      return true;
    },
    open() {},
    formatedCellValue(row, columnDefine) {
      let keyName = columnDefine.keyName;
      let type = columnDefine.type;
      let v = row[keyName];
      let empty = false;
      if (v === undefined || v === null) empty = true;
      switch (type) {
        case 'STRING':
          if (empty) return '--';
          return v;
        case 'MONEY':
          if (empty) return '--';
          return this.formatedMoney(v);
        case 'PERCENTAGE':
          if (empty) return '--';
          return parseFloat(v).toFixed(2) + '%';
        case 'INT':
          if (empty) return '--';
          return parseInt(v);
        case 'DECIMAL':
          if (empty) return '--';
          return parseFloat(v).toFixed(2);
        default:
          return v;
      }
    },
    formatedMoney(v) {
      return '$' + (parseFloat(v) / 100).toFixed(2);
    },
    // 获取实时数量
    getNum(level, num) {
      this.numObj[level] = num;
    },
    // 刷新表格数据
    refreshTable(ref) {
      this.$refs[ref].debouncedReloadTable();
    },
    // 获取草稿数量
    getDraftNum(v) {
      this.adsCountObj.drafts = v;
    },
    // field
    operatorName(v, tag) {
      return v
        ? v.filtering.filter((m) => m.key == tag.operator)[0]
          ? v.filtering.filter((m) => m.key == tag.operator)[0].desc
          : ''
        : '请选择';
    },
    // 显示筛选条件符号下拉
    showFiltering(tag) {
      // console.log(tag);
      let list = this.initData.FilterFields.filter((v) => v.keyName == tag.field);
      // console.log(list);
      if (list.length) {
        return list[0].filtering;
      } else {
        return [];
      }
    },
    // 显示筛选条件的值
    valueName(tag) {
      let list = [];
      tag.value1.map((item) => {
        // console.log(item);
        if (tag.field == 'objective') {
          this.initData.CampaignObjective.map((v) => {
            if (item == v.key) {
              list.push(v.desc);
            }
          });
        } else {
          this.initData.FilteringStatus.map((v) => {
            if (item == v.key) {
              list.push(v.desc);
            }
          });
        }
      });
      return list ? list : '请选择';
    },
    // checkList

    // 获取常量数据
    async getInitData() {
      await reportConsts().then((res) => {
        this.initData = res.data;
      });
    },
    // 广告数量合计
    adsCount() {
      let params = { accountId: this.$store.getters.accountId };
      adsCount(params).then((res) => {
        if (res.code == 0) {
          this.adsCountObj = { ...this.adsCountObj, ...res.data };
          this.numObj = { ...res.data };
          // console.log("res.data,this.adsCountObj",res.data,this.adsCountObj)
        }
      });
    },
    // 切换筛选条件
    handleFilterCommand(v) {
      this.params.filtering = v.filtering;
    },
    // 删除当前筛选条件
    deleFilterTag(tag, i) {
      this.params.filtering.splice(i, 1);
      // if(tag.field && tag.operator &&(tag.value || tag.value1.length)){

      // }
    },
    // 监听筛选条件field的变化
    fieldChange(list, v, i) {
      let obj = JSON.parse(JSON.stringify(v));
      if (obj.field == 'status' || obj.field == 'objective') {
        obj.operator = 'IN';
      } else {
        obj.operator = '';
      }
      obj.value = '';
      obj.value1 = [];
      this.$set(list, i, obj);
    },
    // 删除筛选条件
    deleFilterObj(i) {
      this.cusFilterList.splice(i, 1);
    },
    // 控制筛选条件下拉不被隐藏
    showFilterDrop() {
      this.$nextTick((res) => {
        this.$refs.filterDrop.dropdown.show();
      });
    },
    // 添加筛选条件
    addFilter() {
      let cusFilterList = this.cusFilterList;
      let flag = true;
      cusFilterList.map((item) => {
        if (!item.field || !item.operator || (!item.value && !item.value1.length)) {
          this.$message({
            type: 'warning',
            message: '请填写完整的信息',
          });
          flag = false;
        } else {
        }
      });
      if (flag) {
        this.cusFilterList.push({ field: '', operator: '', value: '', value1: [] });
      }
      return flag;
    },
    // 添加当前筛选条件
    addTag() {
      let obj = { field: '', operator: '', value: '', value1: [] };
      this.params.filtering.push(obj);
    },
    // 保存为我的筛选条件
    saveMyFilter() {
      let data = {
        name: this.filterName,
      };
      let filters = this.params.filtering.filter((v) => v.field != 'status' && v.field != 'objective');
      data.filtering = filters;
      this.$showLoading();
      saveFilter(JSON.stringify(data)).then((res) => {
        // console.log(res);
        this.$hideLoading();
        if (res.code == 0) {
          this.saveFilterShow = false;
          this.filterName = '';
          this.getMyFilter();
        }
      });
    },
    // 关闭当前的筛选条件
    closeMyFilter() {
      this.filterShow = false;
      this.cusFilterList = [{ field: '', operator: '', value: '', value1: [] }];
      this.params.filtering = [];
    },
    // 保存为当前的筛选条件
    saveFilterSearch() {
      if (this.addFilter()) {
        // console.log(this.cusFilterList);
        this.filterShow = true;
        this.params.filtering = JSON.parse(JSON.stringify(this.cusFilterList)).slice(0, this.cusFilterList.length - 1);
        if (this.params.filtering.filter((v) => v.field == 'status').length) {
          this.checkList = this.params.filtering.filter((v) => v.field == 'status')[0].value1;
        }
      }
    },
    // 删除我的查询条件
    deleFilterGroup(v) {
      let formData = new FormData();
      formData.append('id', v);
      this.$showLoading();
      deleteFilter(formData).then((res) => {
        this.$showLoading();
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '删除成功',
          });
          this.getMyFilter();
        }
      });
    },
    // 获取我的查询条件
    getMyFilter() {
      myFilters().then((res) => {
        this.filterList = res.data;
      });
    },
    // 获取我之前定制的列组合
    async myColumns() {
      const params = { accountId: this.$store.getters.accountId };
      await myColumns(params).then((res) => {
        if (res.code == 0) {
          this.myCols.myColumnsArr = res.data;
          let myColumsId = localStorage.getItem('myColumsId');
          if (myColumsId) {
            this.fields = res.data.filter((v) => v.id == myColumsId)[0].columns;
          } else {
            this.fields = res.data[0].columns;
          }
          this.myCols.myColumsId = myColumsId;
        }
      });
    },
    // 监听表格排序
    sortChange(v) {
      // console.log(v);
      let obj = {
        field: v.prop,
        direction: v.order ? (v.order == 'ascending' ? 'ASC' : 'DESC') : '',
      };
      this.params.after = '';
      // console.log(obj);
      this.params.sort = [obj];
    },
    // 点击创建广告类型
    handleCommandClick() {
      this.handleCommand(this.adType);
    },
    handleCommand(v) {
      // console.log('handleCommand',v);
      this.adType = v;
      if (v === '创建标准广告') {
        this.createStandardAd({ name: 'CreateAd' });
      } else {
        this.createStandardAd({ name: 'createFastAd' });
      }
    },
    // 跳转路由
    createStandardAd(router) {
      this.$router.push(router);
    },
    // 表格表头筛选
    selectStatus(v) {
      // console.log(v);
      let flag = this.params.filtering.filter((v) => v.field == 'status').length;
      if (this.params.filtering.filter((m) => m.field == 'status').length) {
        this.params.filtering.map((item, i) => {
          if (item.field == 'status') {
            item.value1 = v;
            item.value = '';
            this.$set(this.params.filtering, i, item);
          }
        });
      } else {
        let obj = { field: 'status', operator: 'IN', value1: v, value: '' };
        this.params.filtering.push(obj);
      }
      // console.log(this.params.filtering);
    },
    refreshTableRow(scope) {
      let row = scope.row;
      // 如果存在子级重新加载子级
      let obj = this.maps.get(row.id);
      // console.log(obj);
      if (obj) {
        const { tree, treeNode, resolve } = this.maps.get(row.id);
        this.$set(this.$refs.table.store.states.lazyTreeNodeMap, row.id, []);
        this.loadChildren(tree, treeNode, resolve);
      } else {
      }
      this.$set(this.tableData, scope.$index, scope.row);
    },
    // 细分选项
    selectBreakdown(v) {
      // console.log(this.breakdowms);
      // let list = this.breakdowms.time+','+this.breakdowms.delivery;
      let str = '';
      for (let key in this.breakdowms) {
        if (this.breakdowms[key]) {
          str += ',' + this.breakdowms[key];
        }
      }
      this.breakdownGroup = str.split(',');
      this.breakdownGroup.map((item, index) => {
        if (!item) {
          this.breakdownGroup.splice(index, 1);
        }
      });
      this.breakdownAds();
    },
    // expandChange 切换表格的展开项
    expandChange(row, expanded) {
      // console.log(row, expanded);
      // console.log(JSON.stringify(this.$refs.table.store.states.treeData));
      // console.log(this.$refs.table);
      if (
        !this.breakdownGroup.length &&
        !this.$refs.table.store.states.lazyTreeNodeMap[row.id] &&
        expanded &&
        row.level != 'ad'
      ) {
        if (this.maps.get(row.id)) {
          const { tree, treeNode, resolve } = this.maps.get(row.id);
          this.loadChildren(tree, treeNode, resolve);
        }
      }
    },
    // 加载列表的细分项
    breakdownAds() {
      let { action_attribution_windows, date_start, date_end, fields, level } = this.params;
      let params = { action_attribution_windows, date_start, date_end, fields, level };
      params.breakdowns = this.breakdownGroup;
      if (this.breakdownGroup.length) {
      } else {
        this.$set(this.$refs.table.store.states, 'lazy', true);
        Object.keys(this.$refs.table.store.states.lazyTreeNodeMap).map((v) => {
          delete this.$refs.table.store.states.lazyTreeNodeMap[v];
        });
        for (let key in this.$refs.table.store.states.treeData) {
          this.$refs.table.store.states.treeData[key].loaded = false;
          this.$refs.table.store.states.treeData[key].expanded = false;
          this.$refs.table.store.states.treeData[key].children = [];
          this.$set(this.$refs.table.store.states.treeData, key, this.$refs.table.store.states.treeData[key]);
        }
        this.tableData.map((item, index) => {
          item.hasChildren = true;
          this.$refs.table.toggleRowExpansion(item, false);
          this.$set(this.tableData, index, item);
        });
        // this.$refs.table.doLayout();
        return false;
      }
      params.account_id = this.$store.getters.accountId;
      params.ids = this.tableData.map((item) => item.id);
      params.level = this.level;
      breakdown(JSON.stringify(params)).then((res) => {
        this.tableData.map((item, index) => {
          let list = res.data.data.filter((v) => v.breakdown_object_id == item.id);
          item.hasChildren = true;
          this.$set(this.tableData, index, item);
          this.$refs.table.toggleRowExpansion(item, true);
          this.$refs.table.store.states.treeData[item.id].loaded = false;
          this.$refs.table.store.states.treeData[item.id].expanded = true;
          this.$set(this.$refs.table.store.states.lazyTreeNodeMap, item.id, list);
          this.$set(this.$refs.table.store.states.treeData, item.id, this.$refs.table.store.states.treeData[item.id]);
        });
      });
    },
    // 加载广告系列子集
    loadChildren(tree, treeNode, resolve) {
      let level = '';
      this.maps.set(tree.id, { tree, treeNode, resolve });
      if (this.$refs.table.store.states.lazyTreeNodeMap[tree.id]) {
        resolve(this.$refs.table.store.states.lazyTreeNodeMap[tree.id]);
        // this.$refs.table.toggleRowExpansion(tree,false)
        return false;
      }
      // console.log(tree.level);
      this.params.object_filtering.map((k, i) => {
        if (k.field == 'adset.id' || k.field == 'campaign.id') {
          this.params.object_filtering.splice(i, 1);
        }
      });
      if (tree.level == 'campaign') {
        level = 'adset';
        let obj = { field: 'campaign.id', operator: 'IN', value: [tree.id] };
        this.params.object_filtering.push(obj);
      } else if (tree.level == 'adset') {
        level = 'ad';
        let obj = { field: 'adset.id', operator: 'IN', value: [tree.id] };
        this.params.object_filtering.push(obj);
      } else {
        setTimeout(() => {
          resolve([]);
        }, 100);
        return false;
      }
      this.params.level = level;
      this.getList({ level: level, after: '' }).then((res) => {
        setTimeout(() => {
          resolve(res);
        }, 200);
      });
    },
    // 监听选择表格的选择项
    handleSelectionChange(level, selection) {
      console.log('level,selection', level, selection);
      this.selectedRowScopes = [...selection];
      if (level == 'campaign') {
        this.selectCampaigns = selection;
        this.selectAdsets = [];
        this.selectAds = [];
      } else if (level == 'adset') {
        this.selectAdsets = selection;
        this.selectAds = [];
      } else if (level == 'ad') {
        this.selectAds = selection;
      }
      // ctrl+d 快捷打开复制广告系列-广告组-广告
      this.listenerCtrlD();
      console.log('2-this.selectIds', this.selectIds);
    },
    // 获取表格细分项
    breakdownRules() {
      let params = { date_end: this.params.date_end, date_start: this.params.date_start };
      breakdownRules(params).then((res) => {
        if (res.code == 0) {
          this.breakdownRulesArr = res.data;
          this.breakdownRulesArr.map((item) => {
            item.value = item.by;
            item.rules.map((item1) => {
              if (!item1.disable_when) {
                item1.disable_when = [];
              }
            });
          });
        }
      });
    },
    // 下拉加载更多
    loadMore() {
      let data = {
        after: this.after,
        level: 'campaign',
      };
      this.loadText = '努力加载中...';
      this.getList(data).then((res) => {
        if (res.length) {
          this.loadText = '滚动加载更多';
        } else {
          this.loadText = '没有更多数据了';
        }
        this.tableData = this.tableData.concat(res);
      });
    },
    // 获取列表
    getList(data) {
      let params = JSON.parse(JSON.stringify(this.params));
      if (data) {
        params = { ...params, ...data };
      }
      // console.log(params.filtering);
      this.loading = params.level == this.level && !params.after;
      let filedList = this.fields.map((v) => v.keyName);
      params.fields = ['name', 'status', 'id'].concat(filedList);
      params.account_id = this.$store.getters.accountId;
      if (params.level == this.level) {
        // console.log(params.level == this.level);
        params.object_filtering = params.object_filtering.filter(
          (v) => v.field != 'adset.id' && v.field != 'campaign.id',
        );
      } else {
        params.after = '';
      }
      // 处理筛选条件
      let filtering = JSON.parse(JSON.stringify(params.filtering)).filter(
        (v) => (v.value || v.value1.length) && v.field && v.operator,
      );
      // console.log(filtering);
      filtering.map((item) => {
        if (item.field == 'status' || item.field == 'objective') {
          item.value = JSON.parse(JSON.stringify(item.value1));
        }
        delete item.value1;
      });
      // console.log(filtering);
      params.object_filtering = filtering.filter(
        (v) => v.field == 'status' || (v.field == 'objective' && v.value.length),
      );
      params.filtering = filtering.filter((v) => v.field != 'status' && v.field != 'objective' && v.value);
      // console.log(params);
      // this.params = params;
      this.infiniteScrollDisabled = true;
      return report(JSON.stringify(params)).then((res) => {
        // console.log(params.level);
        this.loading = false;
        this.infiniteScrollDisabled = false;
        if (params.level == this.level) {
          // console.log(params.level);
          this.after = res.data.paging.after;
        }
        if (this.params.level != 'ad') {
          // console.log(this.params.level);
          res.data.data.map((v) => {
            v.hasChildren = true;
          });
        }
        this.$refs.table.doLayout();
        // this.tableData = res.data.data;
        return res.data.data ? res.data.data : [];
      });
    },
    handleClickTab(v) {
      this.$store.commit('adActiveTab/setActiveTab', v.name);
      if (v.name !== 'drafBox') {
        if (v.name == 'adset' && v.id) {
          this.selectIds.selectCampaigns = v.id;
        } else if (v.name == 'ad' && v.id) {
          this.selectIds.selectAdsets = v.id;
        }
        this.level = this.activeTab;
        if (v.name == 'campaign') {
          if (this.$refs.campaignTable) this.$refs.campaignTable.tabChangeReloadTable('campaign');
        }
        if (v.name == 'adset') {
          if (this.$refs.adsetTable) this.$refs.adsetTable.tabChangeReloadTable('adset');
        }
        if (v.name == 'ad') {
          if (this.$refs.adTable) this.$refs.adTable.tabChangeReloadTable('ad');
        }
        // console.log(this.selectIds);
      }
    },
    selectTab(v) {
      console.log('selectTab(v)', v);
      this.activeTab = v.name;
      if (v.name == 'adset') {
        this.$store.commit('adActiveTab/setSelectCampaignNew', [v.row]);
      }
      if (this.level == 'ad') {
        this.$store.commit('adActiveTab/setSelectAdsetsNew', [v.row]);
      }
      this.$store.commit('adActiveTab/setActiveTab', v.name);

      if (v.name !== 'drafBox') {
        if (v.name == 'adset' && v.row) {
          this.selectIds.selectCampaigns = [v.row];
          if (this.$refs.adsetTable) this.$refs.adsetTable.tabChangeReloadTable('adset');
        } else if (v.name == 'ad' && v.row) {
          this.selectIds.selectAdsets = [v.row];
          if (this.$refs.adTable) this.$refs.adTable.tabChangeReloadTable('ad');
        }
      }
    },
  },
};
</script>
<style lang="scss">
.adManagement-container .el-tabs__content {
  padding: 0;
}

.adManagement-container {
  position: relative;
  .el-table .caret-wrapper {
    width: 0;
  }
  .datePicker {
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 2;
    width: 580px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.toolsCheckTips {
  .el-checkbox,
  .el-radio {
    line-height: 36px;
  }
}

.dropselect {
  .el-select-dropdown__item {
    padding: 0;

    span {
      display: block;
      padding: 0 20px;
    }
  }
}

.el-table__body-wrapper {
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}

.statusWarp {
  display: flex;
  align-items: center;

  .pointer {
    flex: 8px 0 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.breakdownList {
  li {
    line-height: 26px;
  }
}

.cusFilter {
  padding: 0 10px 0 20px;

  .cusFilterList {
    .cusFilterItem {
      margin-bottom: 10px;
    }
  }
}

.el-dropdown-menu__item {
  &.active {
    background: #ecf5ff;
    color: #66b1ff;
  }

  .columnName {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      p {
        display: block;
      }
    }

    div {
      p {
        flex: 60px 0 0;
        display: none;

        i {
          margin: 0 5px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}

.tagInput {
  width: 30px;

  input {
    width: auto;
    border: none;
    outline: none;
    background: transparent;
    padding: 0 5px;
    width: 30px;
    border-bottom: 1px dashed #fff;
    color: #fff;
    height: 18px;
    line-height: 18px;
  }
}

.el-popover {
  min-width: 100px;
}
</style>
<style lang="scss" scoped>
.tableWrap {
  padding: 16px;
  padding-top: 0;

  .loadMore {
    text-align: center;
    line-height: 28px;
    color: #999;
  }
}

.el-table .caret-wrapper {
  width: 0;
}

.adManagement-container {
  width: 100%;
  height: calc(100vh - 82px);
  background: #fff;

  .filterWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ecf5ff;

    .el-image {
      flex: 14px 0 0;
      margin-left: 5px;
    }

    .paramsFilters {
      flex: 1;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .el-tag {
        margin: 3px;
        display: flex;
        align-items: center;

        .checkbox-group {
          padding: 0 10px;

          .el-checkbox {
            display: block;
          }
        }
      }
    }

    .rightIcon {
      flex: 40px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      i {
        font-size: 15px;
        font-weight: bold;
        margin: 0 5px;
        cursor: pointer;
        color: #666;
      }
    }
  }

  .el-tabs__item {
    .el-button {
      padding: 4px 10px;
      margin-left: 5px;
    }
  }

  .table-top-bar {
    // position: absolute;
    // top: 56px;
    display: flex;
    align-items: center;
    width: 100%;
    // height: 32px;
    padding: 16px;
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rightTools {
      flex: 260px 0 0;
      display: flex;
      align-items: center;
      text-align: right;
      justify-content: flex-end;

      .tools {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .toolsItem {
          margin: 0 4px;
        }
      }

      .searchInput {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .el-button {
          color: #666;
          font-size: 14px;
          margin: 0 5px;
        }
      }
    }

    .midFilter {
      flex: 1;
      padding: 0 10px;
    }

    .table-top-bar-right {
      flex: 1 1 0%;
      display: flex;
      align-items: center;
      position: relative;
      transition: all 0.3s linear 0s;
      // padding-right: 140px;
      .filter-btn {
        display: flex;
        align-items: center;
        height: 32px;
        font-size: 12px;
        color: #8c8c8c;
        margin-left: 12px;
        padding: 0 4px;
        border-radius: 2px;
        transition: all 0.3s linear 0s;
        cursor: pointer;

        img {
          margin-right: 4px;
        }
      }

      .filter-btn:hover {
        background: #eaeaed;
      }

      .filter-operation {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        width: auto;
        padding-left: 10px;
        transition: all 0.3s linear 0s;

        .filter-operation-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          transition: all 0.3s linear 0s;
          cursor: pointer;

          i {
            color: gray;
          }
        }

        .filter-operation-item:hover {
          background: #eaeaed;
        }
      }
    }
  }
}
</style>
